<template>
  <div class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done" style="height: 100vh; overflow-y: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-side-menu :active-index="1"></contract-side-menu>
        <div class="flex-auto">
        <el-scrollbar>
          <div class="flex flex-auto flex-column overflow-y-auto pl1">
            <div  class="calendar-container">
              <el-calendar>
                <template #dateCell="{ data  }">
                  <div class="date-container">
                    <div class="date-date" v-html="getDateText(data.day)"></div>
                    <div class="date-events-container">
                      <div class="date-event" v-for="(event, index) in eventsData[`${data.day}`]">
                        {{event.name}}
                      </div>
                    </div>
                  </div>
                </template>
              </el-calendar>
            </div>
          </div>
        </el-scrollbar>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// 合同的过滤器类型
import config from "../config";
import {
} from "../api/api";
import {ElLoading, ElMessage} from "element-plus";
import {MetaMessage} from "../util";
import ContractSideMenu from "../components/ContractSideMenu";

export default {
  name: "events",
  components: {ContractSideMenu},
  data: function () {
    return {
      templateHost: config.baseUrl,
      metaMessage: new MetaMessage(),
      dashboardCalender: null,
      eventsData: {
        "2021-12-30": [
          {
            "name": "AAAAAAA",
            "startDate": "2021-12-01",
            "expireDate": "2021-12-31",
            "contractId": 889,
          },
          {
            "name": "BBBBB",
            "startDate": "2021-12-01",
            "expireDate": "2021-12-31",
            "contractId": 886,
          }
        ],
        "2021-12-31": [
          {
            "name": "CCCCCCCCC",
            "startDate": "2021-12-01",
            "expireDate": "2021-12-31",
            "contractId": 889,
          }
        ]
      }
    };
  },
  mounted() {
    this.initEventsCalendar();
  },
  methods: {
    initEventsCalendar: function () {
    },
    getDateText: function (day) {
      const dates = day.split('-');
      return dates[dates.length - 1] + '日';
    }
  },
}
</script>

<style>

  .calendar-container {
    position: relative;
    width: 100%;
    margin: 20px auto 100px auto;
    background: #fbfbfb;
    border-radius: 3px;
    box-shadow: 0px 5px 10px 0px rgba(8,24,60,0.15);
    padding: 40px;
    font-size: 12px;
    box-sizing: border-box;
    min-height: calc(100vh - 300px);
  }

  .calendar-title {
    position: absolute;
    font-size: 1.75em;
    line-height: 35px;
    color: #454D66;
    font-weight: 600;
    height: 35px;
    font-family: inherit;
    vertical-align: baseline;
    cursor: default;
    box-sizing: border-box;
  }

</style>
